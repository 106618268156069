import React from "react"
import { Container, Card, Button, Table } from 'react-bootstrap'
import { postTC } from '../helpers/apiAdmin'
import { getUserAdmin } from '../helpers/auth'
import ModalCategoria from './ModalCategoria'
import Loading from '../components/Loading'

export default class Categorias extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            categorias: [],
            categoria: null,
            showBackupModal: false,
            loading: false,
        }
    }

    componentDidMount() {
        this.update()
    }

    update = () => {
        this.setState({ loading: true })
        postTC('api/categorias', {
            ...getUserAdmin()
        }, (json) => {
            if (json.error) {
                this.setState({ error: json.ret })
            } else {

                const state = {
                    categorias: json.ret.map(cat => {
                        cat.super = json.ret.filter(c => c.id === cat.super)[0]
                        return cat
                    }),
                    idCategoria: null,
                    showModal: false,
                    loading: false,
                }
                console.log(state)
                this.setState(state)
            }
            this.setState({ loading: false })
        });
    }

    show = (categoria) => {
        this.setState({
            showModal: true,
            categoria: categoria
        })
    }

    del = (categoria) => {
        this.setState({ loading: true })
        postTC('api/delCategoria', {
            ...getUserAdmin(),
            id: categoria.id,
        }, (json) => {
            this.update()
        });
    }

    render() {
        return (
            <Container>
                <Card>
                    <Loading show={this.state.loading} />
                    <Card.Header>Backups</Card.Header>
                    <Card.Body>
                        <Table striped hover responsive>
                            <tbody>
                                <tr>
                                    <th>ID</th>
                                    <th></th>
                                    <th></th>
                                    <th>Super</th>
                                    <th>Nome</th>
                                    <th>Ordem</th>
                                    <th>Icone</th>
                                    <th>Imagem</th>
                                </tr>
                                {this.state.categorias.map((c, i) => <tr key={`bkp-${i}`}>
                                    <td>{c.id}</td>
                                    <td><div className="edit" onClick={() => this.show(c)} /></td>
                                    <td><div className="del" onClick={() => this.del(c)} /></td>
                                    <td>{c.super ? c.super.nome : 'Não há'}</td>
                                    <td>{c.nome}</td>
                                    <td>{c.ordem}</td>
                                    <td><img style={{width: 40, height: 40}} src={c.icone} alt="button icon"/></td>
                                    <td><img style={{width: 100}} src={c.imagem} alt="button icon"/></td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer>
                        <Button onClick={() => this.show(null)}>Nova Categoria</Button>
                    </Card.Footer>
                </Card>
                <ModalCategoria
                    show={this.state.showModal}
                    setShow={show => { this.setState({ showModal: show }); this.update() }}
                    categoria={this.state.categoria} />
            </Container>
        );
    }
}