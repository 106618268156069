
const API = 'https://sistematudok.com/api/'
//const API = 'http://totalconfident.com/tudok/'

export const postTC = async (endpoint, body, affter) => {
    var error = false;
    var text = 'Falha na conexão';
    await fetch(API + endpoint, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: JSON.stringify(body)
    }).then(r => {
        if (!r.ok) {
            error = true
        }
        return r.text()
    }).then(r => {
        text = r;
        return JSON.parse(r)
    }).then(r => {
        text = r;
    }).catch((ex) => {
        error = true
        console.log('body: '+text)
        console.log(ex)
    })
    affter({ error, ret: text });
}

export const postImagensCarolar = async (body, affter) => {
    var error = false;
    var text = 'Falha na conexão';
    await fetch('https://sistematudok.com/others/uploadImagemDashboard.php', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }).then(r => {
        if (!r.ok) {
            error = true
        }
        return r.text()
    }).then(r => {
        text = r;
        return JSON.parse(r)
    }).then(r => {
        text = r;
    }).catch((ex) => {
        error = true
        console.log('body: '+text)
        console.log(ex)
    })
    affter({ error, ret: text });
}

export const postBoletos = async (endpoint, body, affter) => {
    var error = false;
    var text = 'Falha na conexão';
    await fetch('https://sistematudok.com/boletos/' + endpoint, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }).then(r => {
        if (!r.ok) {
            error = true
        }
        return r.text()
    }).then(r => {
        text = r;
        return JSON.parse(r)
    }).then(r => {
        text = r;
    }).catch((ex) => {
        error = true
        console.log('body: '+text)
        console.log(ex)
    })
    affter({ error, ret: text });
}

export const getBoletos = async (endpoint, affter) => {
    var error = false;
    var text = 'Falha na conexão';
    await fetch('https://sistematudok.com/boletos/' + endpoint, {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    }).then(r => {
        if (!r.ok) {
            error = true
        }
        return r.text()
    }).then(r => {
        text = r;
        return JSON.parse(r)
    }).then(r => {
        text = r;
    }).catch((ex) => {
        error = true
        console.log('body: '+text)
        console.log(ex)
    })
    affter({ error, ret: text });
}
