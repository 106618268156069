import React from "react"

class CustomCheckbox extends React.Component {

    stateChanged = (event) => {
        event.target.value = event.target.checked
        this.props.onChange(event)
    }

    render() {
        return (<div className="custom-control custom-switch" style={{marginTop:10, marginBottom: 10, marginLeft: 5}}>
            <input
                className="custom-control-input"
                id={this.props.name}
                name={this.props.name}
                type="checkbox"
                checked={this.props.value}
                onChange={this.stateChanged} />
            <label className="custom-control-label" htmlFor={this.props.name}>{this.props.label}</label>
        </div>)
    }
}

export default CustomCheckbox
