import React from "react"
import { Container, Card, Button, Form, Dropdown, DropdownButton, Row, Col, Table, Badge } from 'react-bootstrap'
import { postTC } from '../helpers/apiAdmin'
import { getUserAdmin } from '../helpers/auth'
import ModalPayment from './ModalPayment'
import Loading from './../components/Loading'
import CustomCheckbox from "../components/CustomCheckbox"
import CustomSelector from "../components/CustomSelector"

const StatusType = ["Ativo", "Cancelado", "Com o Vitor"]
const PaymentType = ["Mensal", "Trimestral", "Semestral", "Anual"]

class Client extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pags: [],
            form: {
                id_tudok: "",
                status: StatusType[0],
                nome: "",
                nome_admin: "",
                email: "",
                tel1: "",
                tel2: "",
                endereco: "",
                mensalidade: "",
                instalacao: "",
                tipo_contrato: PaymentType[0],
                send_whatsapp: true,
                dia: "",
                init: "",
                end: "",
                modulos: "",
                obs: "",
            },
            id_client: null,
            novoCliente: true,
            error: null,
            loading: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.update()
    }

    updateForm = (event) => {
        let form = this.state.form
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        form[event.target.name] = value
        this.setState({ form: form })
    }

    updateStatus = (status) => {
        let form = this.state.form
        form['status'] = status
        this.setState({ form: form })
    }

    updateType = (tipo) => {
        let form = this.state.form
        form['tipo_contrato'] = tipo
        this.setState({ form: form })
    }

    update = () => {
        var id = this.props.match.params.id
        if (id) {
            this.setState({ loading: true })
            postTC('api/client', {
                ...getUserAdmin(),
                id
            }, (json) => {
                if (json.error) {
                    this.setState({ error: json.ret })
                } else {
                    json.ret.pags = json.ret.pags.map(pag => {
                        pag.valor = parseFloat(pag.valor)
                        pag.data = new Date(pag.data + " 12:00:00Z")
                        pag.recebido = new Date(pag.recebido + " 12:00:00Z")
                        pag.status = pag.status.charAt(0).toUpperCase() + pag.status.slice(1).toLocaleLowerCase()
                        return pag
                    })
                    var state = {
                        pags: json.ret.pags,
                        form: {
                            ...JSON.parse(json.ret.client.json)
                        },
                        id_client: id,
                        novoCliente: false,
                        error: null,
                    }
                    console.log(state.form)
                    //console.log(state)
                    this.setState(state)
                }
                this.setState({ loading: false })
            });
        }
    }

    save = () => {
        this.setState({ loading: true })
        var form = this.state.form
        form.menu_enable = (form.menu_enable === 'true' || form.menu_enable === true) ? true : false
        postTC('api/clientSave', {
            ...getUserAdmin(),
            client: form,
            id: this.state.id_client,
        }, (json) => {
            //console.log(json.ret)
            if (json.error) {
                this.setState({ error: json.ret })
            } else if (this.state.novoCliente) {
                this.props.history.replace('/dashboard/cliente/' + json.ret.id)
                this.props.history.go(0)
            }
            this.setState({ loading: false })
        });
    }

    delPayment = (pag) => {
        this.setState({ loading: true })
        postTC('api/paymentDel', {
            ...getUserAdmin(),
            id_pagamento: pag.id_pagamento,
        }, (json) => {
            this.update()
        });
    }

    editPayment = (pag) => {
        //console.log(pag)
        this.setState({
            idPaymentModal: pag.id_cliente,
            cliente: this.state.form,
            showPaymentModal: true,
            paymentModal: pag
        })
    }

    newPayment = () => {
        //console.log(pag)
        this.setState({
            idPaymentModal: this.state.id_client,
            showPaymentModal: true,
            paymentModal: null
        })
    }

    render() {
        return (
            <Container>
                <Card>
                    <Loading show={this.state.loading} />
                    <Card.Header>{this.state.novoCliente ? 'Novo ' : ''}Cliente</Card.Header>
                    <Card.Body><Form>
                        <Form.Group>
                            <Row>
                                <Col>
                                    <Form.Label>ID TudOK</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="id_tudok"
                                        value={this.state.form.id_tudok}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Status</Form.Label>
                                    <DropdownButton title={` ${this.state.form.status}`}>
                                        {StatusType.map(status =>
                                            <Dropdown.Item onClick={() => this.updateStatus(status)} key={status}>
                                                {status}
                                            </Dropdown.Item>)}
                                    </DropdownButton>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nome"
                                        value={this.state.form.nome}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Responsável</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nome_admin"
                                        value={this.state.form.nome_admin}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Início Contrato</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="init"
                                        value={this.state.form.init}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Primeira Mensalidade</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="primeira_mensalidade"
                                        value={this.state.form.primeira_mensalidade}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Fim Contrato</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="end"
                                        value={this.state.form.end}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Mensalidade</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="mensalidade"
                                        value={this.state.form.mensalidade}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Instalação</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="instalacao"
                                        value={this.state.form.instalacao}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomSelector
                                        name="tipo_contrato"
                                        label="Tipo Contrato"
                                        values={PaymentType}
                                        value={this.state.form.tipo_contrato}
                                        onChange={this.updateForm}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Dia De Vencimento</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="dia"
                                        value={this.state.form.dia}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Telefone 1</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="tel1"
                                        value={this.state.form.tel1}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Telefone 2</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="tel2"
                                        value={this.state.form.tel2}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        value={this.state.form.email}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Endereço</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="endereco"
                                        value={this.state.form.endereco}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                                <Col>
                                    <Form.Label>Observações</Form.Label>
                                    <Form.Control
                                        as="textarea" rows="3"
                                        name="obs"
                                        value={this.state.form.obs}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomCheckbox
                                        name="send_whatsapp"
                                        label="Enviar via whatsapp"
                                        value={this.state.form.send_whatsapp}
                                        onChange={this.updateForm}
                                    />
                                </Col>
                            </Row>
                            <Row><Col><h5>Delivery Online</h5></Col></Row>
                            <Row>
                                <Col>
                                    <Form.Label>Contato Whatsapp</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="menu_whatsapp"
                                        value={this.state.form.menu_whatsapp}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Link</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="link"
                                        value={this.state.form.link}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Endereço</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="menu_address"
                                        value={this.state.form.menu_address}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Onde Entrega</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="menu_address_delivery"
                                        value={this.state.form.menu_address_delivery}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Observação</Form.Label>
                                    <Form.Control
                                        as="textarea" rows="3"
                                        name="menu_info"
                                        value={this.state.form.menu_info}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Horário de funcionamento</Form.Label>
                                    <Form.Control
                                        as="textarea" rows="3"
                                        name="menu_opened"
                                        value={this.state.form.menu_opened}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomCheckbox
                                        name="menu_enable"
                                        label="Habilita Pesquisa"
                                        value={this.state.form.menu_enable}
                                        onChange={this.updateForm}
                                    />
                                </Col>
                                <Col>
                                    <CustomCheckbox
                                        name="menu_multi"
                                        label="Multipla Escolha"
                                        value={this.state.form.menu_multi}
                                        onChange={this.updateForm}
                                    />
                                </Col>
                                <Col>
                                    <CustomCheckbox
                                        name="menu_show_fee"
                                        label="Mostrar taxa delivery"
                                        value={this.state.form.menu_show_fee}
                                        onChange={this.updateForm}
                                    />
                                </Col>
                                <Col>
                                    <CustomCheckbox
                                        name="menu_colors"
                                        label="Cores Personalizadas"
                                        value={this.state.form.menu_colors}
                                        onChange={this.updateForm}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Taxa de entrega</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="menu_fee"
                                        value={this.state.form.menu_fee}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Tempo de espera</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="menu_delivery_delay"
                                        value={this.state.form.menu_delivery_delay}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Header</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="menu_header"
                                        value={this.state.form.menu_header}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Banner</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="menu_banner"
                                        value={this.state.form.menu_banner}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Folder</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="menu_folder"
                                        value={this.state.form.menu_folder}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Background</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="menu_background"
                                        value={this.state.form.menu_background}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form></Card.Body>
                    <Card.Footer>
                        <Button variant="link" onClick={() => this.props.history.goBack()}>Voltar</Button>
                        <Button variant="primary" onClick={() => this.save()}>Salvar</Button>
                    </Card.Footer>
                </Card>
                {!this.state.novoCliente &&
                    <Card>
                        <Loading show={this.state.loading} />
                        <Card.Header>Pagamentos</Card.Header>
                        <Card.Body>
                            <Table striped hover responsive>
                                <tbody>
                                    {this.state.pags.map(pag => {
                                        var variant = 'danger'
                                        if (pag.status === 'Pago' || pag.status === 'Pago com atraso') {
                                            variant = 'success'
                                        } else if (pag.status === 'Liberado do pagamento') {
                                            variant = 'info'
                                        } else if (pag.status === 'Enviado') {
                                            variant = 'warning'
                                        }
                                        return (<tr key={`${pag.id_pagamento}`}>
                                            <td>{pag.data.toLocaleDateString(undefined, {
                                                day: 'numeric',
                                                month: 'numeric',
                                                year: 'numeric'
                                            })}</td>
                                            <td>{pag.descricao}</td>
                                            <td><Badge variant={variant}>{pag.status}</Badge></td>
                                            <td>{!pag.recebido.getTime() ? '' : pag.recebido.toLocaleDateString(undefined, {
                                                day: 'numeric',
                                                month: 'numeric',
                                                year: 'numeric'
                                            })}</td>
                                            <td>R$ {parseFloat(pag.valor).toFixed(2)}</td>
                                            <td><div className="edit" onClick={() => this.editPayment(pag)} /></td>
                                            <td><div className="del" onClick={() => this.delPayment(pag)} /></td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant="primary" onClick={() => this.newPayment()}>Novo Pagamento</Button>
                        </Card.Footer>
                    </Card>}
                <ModalPayment
                    show={this.state.showPaymentModal}
                    setShow={show => { this.setState({ showPaymentModal: show }); this.update() }}
                    payment={this.state.paymentModal}
                    cliente={this.state.cliente}
                    id_cliente={this.state.idPaymentModal} />
            </Container>
        );
    }
}

export default Client
