import React from "react"
import { Navbar, Nav } from 'react-bootstrap'

class Header extends React.Component {

    render() {
        return (<>
            <Navbar bg="primary" variant="dark" expand="md">
                <Navbar.Brand href="/dashboard">Dashboard</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/dashboard/contas">Contas</Nav.Link>
                        <Nav.Link href="/dashboard/pagamentos">Pagamentos</Nav.Link>
                        <Nav.Link href="/dashboard/clientes">Clientes</Nav.Link>
                        <Nav.Link href="/dashboard/backups">Backups</Nav.Link>
                        <Nav.Link href="/dashboard/categorias">Categorias</Nav.Link>
                        <Nav.Link href="/dashboard/precos">Precos</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>)
    }
}

export default Header
