import React from "react"
import { Container, Card, Form, Col } from 'react-bootstrap'
import CustomCheckbox from "../components/CustomCheckbox"
import Loading from './../components/Loading'
import md5 from "js-md5"

export default class Precos extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            precos: [],
            total: 0,
            cod: '',
            result: ''
        }
    }

    componentDidMount() {
        this.update()
    }

    update = async () => {
        this.setState({ loading: true })
        await fetch('https://sistematudok.com/api/info/precos.php').then(r => {
            return r.json()
        }).then(precos => {
            precos = precos.map(item => ({ ...item, checked: false }))
            console.log(precos)
            this.setState({ precos })
        })
        this.setState({ loading: false })
    }

    updateForm = (event) => {
        var precos = this.state.precos
        let checked = event.target.checked
        let nome = event.target.name
        let ehModuloEssencial = nome.startsWith('modulo_principal_x')
        let total = 0
        precos = precos.map(item => {
            if (ehModuloEssencial && item.nome.startsWith('modulo_principal_x')) {
                item.checked = false
            }
            if (nome === item.nome) {
                item.checked = checked
            }
            if (item.checked) {
                total += parseFloat(item.valor)
            }
            return item
        })
        this.setState({ precos, total })
    }

    updateCode = (event) => {
        var cod = event.target.value
        var result = this.hexToDec(md5(cod+'JHfhyadf45412747')).substring(0,4)
        this.setState({ cod, result })
    }

    hexToDec = (s) => {
        var i, j, digits = [0], carry;
        for (i = 0; i < s.length; i += 1) {
            carry = parseInt(s.charAt(i), 16);
            for (j = 0; j < digits.length; j += 1) {
                digits[j] = digits[j] * 16 + carry;
                carry = digits[j] / 10 | 0;
                digits[j] %= 10;
            }
            while (carry > 0) {
                digits.push(carry % 10);
                carry = carry / 10 | 0;
            }
        }
        return digits.reverse().join('');
    }

    render() {
        return (
            <Container>
                <Card>
                    <Loading show={this.state.loading} />
                    <Card.Header>Gerador de preço</Card.Header>
                    <Card.Body>
                        <Form>
                            {this.state.precos.map((item) => {
                                return (
                                    <Col>
                                        <CustomCheckbox
                                            name={item.nome}
                                            label={`R$ ${item.valor} - ${item.descricao}`}
                                            value={item.checked}
                                            onChange={this.updateForm}
                                        />
                                        <p dangerouslySetInnerHTML={{ __html: item.info }}/>
                                    </Col>
                                )
                            })}
                        </Form>
                        <br />
                        <br />
                        <h2>Total: R${this.state.total.toFixed(2)}</h2>
                    </Card.Body>
                </Card>
                <Card>
                    <Loading show={this.state.loading} />
                    <Card.Header>Gerador de código de acesso</Card.Header>
                    <Card.Body>
                        <Form.Control
                            type="text"
                            name="Código"
                            value={this.state.cod}
                            onChange={this.updateCode} />
                        <br />
                        <br />
                        <h2>Chave de acesso: {this.state.result}</h2>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}