import React from "react"
import { Container, Card, Tab, Nav, Table, Badge, Button } from 'react-bootstrap'
import { postTC } from './../helpers/apiAdmin'
import { getUserAdmin } from './../helpers/auth'
import ModalConta from './ModalConta'
import Loading from './../components/Loading'

class Contas extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mounths: [],
            showModal: false,
            conta: null,
            loading: false,
        }
        this.initialTab = (new Date()).toLocaleDateString(undefined, {
            month: 'numeric',
            year: 'numeric'
        })
    }

    componentDidMount() {
        this.update()
    }

    update = () => {
        this.setState({ loading: true })
        postTC('api/contas', {
            ...getUserAdmin()
        }, (json) => {
            if (json.error) {
                this.setState({ error: json.ret })
            } else {
                //console.log(json.ret)
                var mounths = []
                json.ret = json.ret.map(conta => {
                    conta.valor = parseFloat(conta.valor)
                    conta.vencimento = new Date(conta.vencimento + " 12:00:00Z")
                    conta.paga = new Date(conta.paga + " 12:00:00Z")
                    conta.repete = conta.repete === '1'
                    conta.status = conta.status.charAt(0).toUpperCase() + conta.status.slice(1).toLocaleLowerCase()
                    var m = getMounth(mounths, conta.vencimento)
                    m.contas.push(conta)
                    m.total += conta.valor
                    if (conta.status === 'Pago' || conta.status === 'Pago com atraso') {
                        m.totalPago += conta.valor
                    }
                    return conta
                })
                mounths = mounths.sort((a, b) => a.time - b.time)
                mounths.forEach(m => m.contas = m.contas.sort((a, b) => a.vencimento.getTime() - b.vencimento.getTime()))
                const state = {
                    mounths: mounths,
                }
                console.log(state)
                this.setState(state)
            }
            this.setState({ loading: false })
        });
    }

    delConta = (conta) => {
        if (window.confirm("Deseja excluir a conta " + conta.motivo)) {
            this.setState({ loading: true })
            postTC('api/delConta', {
                ...getUserAdmin(),
                id_conta: conta.id_conta,
            }, (json) => {
                this.update()
            });
        }
    }

    editConta = (conta) => {
        this.setState({
            showModal: true,
            conta: conta
        })
    }


    novaConta = () => {
        this.setState({
            showModal: true,
            conta: null
        })
    }

    render() {
        return (
            <Container>
                <Card>
                    <Loading show={this.state.loading} />
                    <Tab.Container transition={false} defaultActiveKey={this.initialTab}>
                        <Card.Header>Contas</Card.Header>
                        <Card.Body><Nav variant="tabs" fill>
                            {
                                this.state.mounths.map(month => <Nav.Item key={month.title}>
                                    <Nav.Link eventKey={month.title}>{month.title}</Nav.Link>
                                </Nav.Item>)
                            }
                        </Nav></Card.Body>
                        <Card.Body><Tab.Content>
                            {this.state.mounths.map(month => <Tab.Pane eventKey={month.title} key={month.title}>
                                <TabelaContas month={month} edit={this.editConta} del={this.delConta} />
                            </Tab.Pane>)}
                        </Tab.Content></Card.Body>
                        <Card.Footer><Button onClick={this.novaConta}>Nova Conta</Button></Card.Footer>
                    </Tab.Container>
                </Card>
                <ModalConta
                    show={this.state.showModal}
                    setShow={show => { this.setState({ showModal: show }); this.update() }}
                    conta={this.state.conta} />
            </Container>
        );
    }
}

const TabelaContas = (props) => {
    return (
        <>
            <Card.Title>
                Contas {props.month.title}<br /><br />
                Total {props.month.total.toFixed(2)}<br />
                Recebido {props.month.totalPago.toFixed(2)}
            </Card.Title>
            <Table striped hover responsive>
                <tbody>
                    <tr>
                        <th></th>
                        <th>Vencimento</th>
                        <th>Descrição</th>
                        <th>Status</th>
                        <th>Paga em</th>
                        <th>Valor</th>
                        <th></th>
                        <th></th>
                    </tr>
                    {
                        props.month.contas.map((conta, i) => {
                            var variant = 'danger'
                            if (conta.status === 'Pago' || conta.status === 'Pago com atraso') {
                                variant = 'success'
                            } else if (conta.status === 'Liberado do pagamento') {
                                variant = 'info'
                            }
                            return (<tr key={`${conta.id_conta}`}>
                                <td>{i + 1}</td>
                                <td>{conta.vencimento.toLocaleDateString(undefined, {
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric',
                                })}</td>
                                <td>{conta.motivo}</td>
                                <td><Badge variant={variant}>{conta.status}</Badge></td>
                                <td>{!conta.paga.getTime() ? '' : conta.paga.toLocaleDateString(undefined, {
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric'
                                })}</td>
                                <td>{parseFloat(conta.valor).toFixed(2)}</td>
                                <td><div className="edit" onClick={() => props.edit(conta)} /></td>
                                <td><div className="del" onClick={() => props.del(conta)} /></td>
                            </tr>)
                        }
                        )
                    }
                </tbody>
            </Table>
        </>
    )
}

const getMounth = (mounths, date) => {
    var title = date.toLocaleDateString(undefined, {
        month: 'numeric',
        year: 'numeric'
    })
    var month = null
    mounths.forEach(m => {
        if (m.title === title) {
            month = m
        }
    })
    if (!month) {
        month = {
            time: date.getTime(),
            title: title,
            total: 0,
            totalPago: 0,
            contas: [],
        }
        mounths.push(month)
    }
    return month
}

export default Contas
