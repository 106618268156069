import React from "react"
import { Container, Card, Tab, Nav, Table, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { postTC } from './../helpers/apiAdmin'
import { getUserAdmin } from './../helpers/auth'
import ModalPayment from './ModalPayment'
import Loading from './../components/Loading'

class Pagamentos extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pags: [],
            mounths: [],
            clients: [],
            idPaymentModal: null,
            showPaymentModal: false,
            paymentModal: null,
            loading: false,
        }
        this.initialTab = (new Date()).toLocaleDateString(undefined, {
            month: 'numeric',
            year: 'numeric'
        })
    }

    componentDidMount() {
        this.update()
    }

    update = () => {
        this.setState({ loading: true })
        postTC('api/dashboard', {
            ...getUserAdmin()
        }, (json) => {
            if (json.error) {
                this.setState({ error: json.ret })
            } else {
                var mounths = []
                json.ret.clientes = json.ret.clientes.map(c => {
                    var json = JSON.parse(c.json)
                    var time = 100000000000
                    try {
                        time = parseInt(json.init.substr(6, 4) + json.init.substr(3, 2) + json.init.substr(0, 2))
                    } catch (ex) {
                    }
                    return {
                        id_cliente: c.id_cliente,
                        ...json,
                        time
                    }
                })
                json.ret.clientes = json.ret.clientes.sort((a, b) => a.time - b.time)
                json.ret.pags = json.ret.pags.map(pag => {
                    pag.valor = parseFloat(pag.valor)
                    pag.data = new Date(pag.data + " 12:00:00Z")
                    pag.recebido = new Date(pag.recebido + " 12:00:00Z")
                    pag.client = getClient(json.ret.clientes, pag.id_cliente)
                    pag.status = pag.status.charAt(0).toUpperCase() + pag.status.slice(1).toLocaleLowerCase()
                    var m = getMounth(mounths, pag.data)
                    m.pags.push(pag)
                    m.total += pag.valor
                    if (pag.status === 'Pago' || pag.status === 'Pago com atraso') {
                        m.totalPago += pag.valor
                    }
                    return pag
                })
                mounths = mounths.sort((a, b) => a.time - b.time)
                mounths.forEach(m => m.pags = m.pags.sort((a, b) => a.data.getTime() - b.data.getTime()))
                const state = {
                    pags: json.ret.pags,
                    mounths: mounths,
                    clients: json.ret.clientes,
                }
                console.log(state)
                this.setState(state)
            }
            this.setState({ loading: false })
        });
    }

    editClient = (id_cliente) => {
        this.props.history.push('/dashboard/cliente/' + id_cliente)
    }

    editPayment = (pag) => {
        //console.log(pag)
        this.setState({
            idPaymentModal: pag.id_cliente,
            cliente: pag.client,
            showPaymentModal: true,
            paymentModal: pag
        })
    }

    render() {
        return (
            <Container>
                <Card>
                    <Loading show={this.state.loading} />
                    <Tab.Container transition={false} defaultActiveKey={this.initialTab}>
                        <Card.Header>Pagamentos</Card.Header>
                        <Card.Body><Nav variant="tabs" fill>
                            {
                                this.state.mounths.map(month => <Nav.Item key={month.title}>
                                    <Nav.Link eventKey={month.title}>{month.title}</Nav.Link>
                                </Nav.Item>)
                            }
                        </Nav></Card.Body>
                        <Card.Body><Tab.Content>
                            {this.state.mounths.map(month => <Tab.Pane eventKey={month.title} key={month.title}>
                                <Payments month={month} edit={this.editPayment} editClient={this.editClient} />
                            </Tab.Pane>)}
                        </Tab.Content></Card.Body>
                    </Tab.Container>
                </Card>
                <ModalPayment
                    show={this.state.showPaymentModal}
                    setShow={show => { this.setState({ showPaymentModal: show }); this.update() }}
                    payment={this.state.paymentModal}
                    cliente={this.state.cliente}
                    id_cliente={this.state.idPaymentModal} />
            </Container>
        );
    }
}

const Payments = (props) => {
    return (
        <>
            <Card.Title>
                Pagamentos {props.month.title}<br /><br />
                Total {props.month.total.toFixed(2)}<br />
                Recebido {props.month.totalPago.toFixed(2)}
            </Card.Title>
            <Table striped hover responsive>
                <tbody>
                    {
                        props.month.pags.map((pag, i) => {
                            var variant = 'danger'
                            if (pag.status === 'Pago' || pag.status === 'Pago com atraso') {
                                variant = 'success'
                            } else if (pag.status === 'Liberado do pagamento') {
                                variant = 'info'
                            } else if (pag.status === 'Enviado') {
                                variant = 'warning'
                            }
                            return (<tr key={`${pag.id_pagamento}`}>
                                <td>{i + 1}</td>
                                <td>{pag.data.toLocaleDateString(undefined, {
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric',
                                })}</td>
                                <td>
                                    <OverlayTrigger placement="right" overlay={<Tooltip>Editar Cliente</Tooltip>}>
                                    <div className="edit" onClick={() => props.editClient(pag.client.id_cliente)} />
                                    </OverlayTrigger>
                                </td>
                                <td>{pag.client.nome}</td>
                                <td>{pag.client.send_whatsapp ? 'Whatsapp' : 'Email'}</td>
                                <td>{pag.descricao}</td>
                                <td><Badge variant={variant}>{pag.status}</Badge></td>
                                <td>{!pag.recebido.getTime() ? '' : pag.recebido.toLocaleDateString(undefined, {
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric'
                                })}</td>
                                <td> R$ {parseFloat(pag.valor).toFixed(2)}</td>
                                <td>
                                    <OverlayTrigger placement="left" overlay={<Tooltip>Editar Pagamento</Tooltip>}>
                                    <div className="edit" onClick={() => props.edit(pag)} />
                                    </OverlayTrigger>
                                </td>
                            </tr>)
                        }
                        )
                    }
                </tbody>
            </Table>
        </>
    )
}

const getMounth = (mounths, date) => {
    var title = date.toLocaleDateString(undefined, {
        month: 'numeric',
        year: 'numeric'
    })
    var month = null
    mounths.forEach(m => {
        if (m.title === title) {
            month = m
        }
    })
    if (!month) {
        month = {
            time: date.getTime(),
            title: title,
            total: 0,
            totalPago: 0,
            pags: [],
        }
        mounths.push(month)
    }
    return month
}

const getClient = (clients, id) => {
    var client = null
    clients.forEach(c => {
        if (c.id_cliente === id) {
            client = c
        }
    })
    return client
}

export default Pagamentos
