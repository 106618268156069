import React from "react"
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { postTC } from './../helpers/apiAdmin'
import { getUserAdmin } from './../helpers/auth'
import CustomCheckbox from "../components/CustomCheckbox"
import CustomSelector from "../components/CustomSelector"

const StatusType = ["Aguardando", "Pago", "Pago com atraso", "Liberado do pagamento"]
const InitialState = {
    valor: '0.00',
    status: StatusType[0],
    vencimento: '',
    paga: '',
    motivo: '',
    referencia: Math.random() * 10000000,
    repete: false,
}


class ModalConta extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id_conta: null,
            error: '',
            form: { ...InitialState }
        }
    }

    save = () => {
        if (this.state.form.vencimento === '') {
            this.setState({ error: 'Data de fatura inválida' })
        } else {
            postTC('api/setConta', {
                ...getUserAdmin(),
                id_conta: this.state.id_conta,
                conta: this.state.form
            }, (json) => {
                console.log(json)
                if (json.error) {
                    this.setState({ error: json.ret })
                } else {
                    this.handleClose()
                }
            });
        }
    }

    updateForm = (event) => {
        let form = this.state.form
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value.replace(',', '.')
        form[event.target.name] = value
        this.setState({ form: form })
    }

    handleClose = () => {
        this.props.setShow(false)
    }

    onShow = () => {
        var state = this.state
        state.error = ''
        state.form.referencia = Math.random() * 10000000
        if (this.props.conta !== null) {
            state.id_conta = this.props.conta.id_conta
            state.form.valor = parseFloat(this.props.conta.valor).toFixed(2)
            state.form.status = this.props.conta.status
            state.form.vencimento = this.props.conta.vencimento.toISOString().substr(0, 10)
            state.form.paga = !this.props.conta.paga.getTime() ? '' : this.props.conta.paga.toISOString().substr(0, 10)
            state.form.motivo = this.props.conta.motivo
            state.form.referencia = this.props.conta.referencia
            state.form.repete = this.props.conta.repete
        } else {
            state.form = { ...InitialState }
            state.id_conta = null
        }
        this.setState(state)
    }

    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.handleClose} onShow={this.onShow}>
                <Modal.Header closeButton>
                    <Modal.Title>Pagamento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Row>
                                <Col>
                                    <Form.Label>Motivo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="motivo"
                                        value={this.state.form.motivo}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Valor</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="valor"
                                        value={this.state.form.valor}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <CustomSelector
                                        name="status"
                                        label="Status"
                                        values={StatusType}
                                        value={this.state.form.status}
                                        onChange={this.updateForm}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Vencimento</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="vencimento"
                                        value={this.state.form.vencimento}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Paga em</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="paga"
                                        value={this.state.form.paga}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomCheckbox
                                        name="repete"
                                        label="Recorrencia"
                                        value={this.state.form.repete}
                                        onChange={this.updateForm}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                    <div>
                        <span className="text-danger">{this.state.error}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" onClick={this.handleClose}>Close</Button>
                    <Button variant="primary" onClick={this.save}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalConta
