import React from "react"
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { postTC } from '../helpers/apiAdmin'
import { getUserAdmin } from '../helpers/auth'
import CustomCheckbox from "../components/CustomCheckbox"

class ModalBackup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            error: '',
            form: {
                addDays: 0,
                blocked: 0,
                info: '',
            },
            name: '',
            id_tudok: 0,
        }
    }

    save = () => {
        postTC('api/backup', {
            ...getUserAdmin(),
            id_tudok: this.state.id_tudok,
            addDays: this.state.form.addDays,
            info: this.state.form.info,
            blocked: this.state.form.blocked ? '1' : '0',
        }, (json) => {
            if (json.error) {
                this.setState({ error: json.ret })
            } else {
                this.handleClose()
            }
        });
    }

    updateForm = (event) => {
        let form = this.state.form
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        form[event.target.name] = value
        this.setState({ form: form })
    }

    updateStatus = (status) => {
        let form = this.state.form
        form['status'] = status
        this.setState({ form: form })
    }

    handleClose = () => {
        this.props.setShow(false)
    }

    onShow = () => {
        var state = {
            error: '',
            form: {
                addDays: this.props.bkp.addDays,
                blocked: this.props.bkp.blocked === '0' ? false : true,
                info: this.props.bkp.info,
            },
            name: this.props.bkp.name,
            id_tudok: this.props.bkp.id_tudok,
        }
        this.setState(state)
    }

    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.handleClose} onShow={this.onShow}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.id_tudok} - {this.state.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <a rel="noopener noreferrer" target="_blank" href={"https://sistematudok.com/api/assets/uploads/" + this.state.id_tudok}>https://sistematudok.com/api/assets/uploads/{this.state.id_tudok}</a><br /><br />
                    <a rel="noopener noreferrer" target="_blank" href={"http://tudok.totalconfident.com/assets/uploads/" + this.state.id_tudok}>http://tudok.totalconfident.com/assets/uploads/{this.state.id_tudok}</a><br /><br />
                    <Form>
                        <Form.Group>
                            <Row>
                                <Col>
                                    <Form.Label>Definir tempo de licença em dias</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="addDays"
                                        value={this.state.form.addDays}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control
                                        as="textarea" rows="3"
                                        name="info"
                                        value={this.state.form.info}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomCheckbox
                                        name="blocked"
                                        label="Bloquear cliente"
                                        value={this.state.form.blocked}
                                        onChange={this.updateForm} />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                    <div>
                        <span className="text-danger">{this.state.error}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" onClick={this.handleClose}>Close</Button>
                    <Button variant="primary" onClick={this.save}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalBackup
