import React from "react"
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { postTC, postImagensCarolar } from '../helpers/apiAdmin'
import { getUserAdmin } from '../helpers/auth'
import FileBase64 from 'react-file-base64'

const InitialForm = {
    nome: '',
    ordem: 0,
    imagem: '',
    icone: '',
    super: 0
}

export default class ModalCategoria extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            error: '',
            file: null,
            form: {
                ...InitialForm
            }
        }
    }

    save = () => {
        if (this.state.form.data === '') {
            this.setState({ error: 'Data de fatura inválida' })
        } else {
            postTC('api/setCategoria', {
                ...getUserAdmin(),
                ...this.state.form,
            }, (json) => {
                if (json.error) {
                    this.setState({ error: json.ret })
                } else {
                    this.handleClose()
                }
            })
        }
    }

    updateForm = (event) => {
        let form = this.state.form
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value.replace(',', '.')
        form[event.target.name] = value
        this.setState({ form: form })
    }

    upload = (field) => {
        postImagensCarolar([this.state.file], (json) => {
            console.log(json)
            if (json.error) {
                this.setState({ error: json.ret })
            } else {
                var state = this.state
                state.form[field] = json.ret[0]
                state.file = null
                this.setState(state)
            }
        })
    }

    getFile = (file) => {
        //console.log(file)
        this.setState({ file })
    }

    handleClose = () => {
        this.props.setShow(false)
    }

    onShow = () => {
        var form = {
            ...InitialForm
        }
        if (this.props.categoria !== null) {
            console.log(this.props.categoria)
            form = this.props.categoria
            form.super = form.super ? form.super.id : 0
        }
        this.setState({ error: '', form })
    }

    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.handleClose} onShow={this.onShow}>
                <Modal.Header closeButton>
                    <Modal.Title>Categoria</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Row>
                                <Col>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nome"
                                        value={this.state.form.nome}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Ordem</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="ordem"
                                        value={this.state.form.ordem}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Super Categoria</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="super"
                                        value={this.state.form.super}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Icone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="icone"
                                        value={this.state.form.icone}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label></Form.Label>
                                    <Button onClick={() => this.upload('icone')} style={{ display: this.state.file != null ? 'block' : 'none' }}>Upload</Button>
                                </Col>
                                <Col>
                                    <Form.Label>Imagem</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="imagem"
                                        value={this.state.form.imagem}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label></Form.Label>
                                    <Button onClick={() => this.upload('imagem')} style={{ display: this.state.file != null ? 'block' : 'none' }}>Upload</Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                    <div>
                        <FileBase64
                            multiple={false}
                            onDone={this.getFile} />
                    </div>
                    <div>
                        <span className="text-danger">{this.state.error}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" onClick={this.handleClose}>Close</Button>
                    <Button variant="primary" onClick={this.save}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
