import React from "react"
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap'
import CustomCheckbox from "../components/CustomCheckbox"
import { postTC } from './../helpers/apiAdmin'
import { loginAdmin, logoutAdmin } from './../helpers/auth'

class LoginAdmin extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            form: {
                user: '',
                pass: '',
                save: false,
            },
            error: null
        }
    }

    componentDidMount() {
        let savedUser = localStorage.getItem('savedUser1') != null ? JSON.parse(localStorage.getItem('savedUser1')) : null;
        if (savedUser) {
            this.setState({
                form: {
                    user: savedUser.user,
                    pass: savedUser.pass,
                    save: true,
                }
            })
        }
        logoutAdmin()
    }

    updateForm = (event) => {
        let form = this.state.form
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        form[event.target.name] = value
        this.setState({ form: form })
    }

    tryLogin = () => {
        postTC('api/login', {
            user: this.state.form.user,
            pass: this.state.form.pass,
        }, (json) => {
            if (json.error) {
                console.log(json.ret)
                this.setState({ error: json.ret })
            } else {
                let savedUser = {
                    user: this.state.form.user,
                    pass: this.state.form.pass
                }
                if (!this.state.form.save) {
                    savedUser = null
                }
                localStorage.setItem('savedUser1', JSON.stringify(savedUser))
                console.log({
                    user: this.state.form.user,
                    token: json.ret.token
                })
                loginAdmin({
                    user: this.state.form.user,
                    token: json.ret.token
                })
                this.props.history.push('/dashboard')
            }
        });
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col sm={1} md={3} lg={4}>
                    </Col>
                    <Col>
                        <Card style={{ width: '100%', marginTop: 20 }}>
                            <Card.Body>
                                <Card.Title className="text-center">Login</Card.Title>
                            </Card.Body>
                            <Card.Body>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control
                                            placeholder="Usuário ou Email"
                                            type="text"
                                            name="user"
                                            value={this.state.form.user}
                                            onChange={this.updateForm} required />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            name="pass"
                                            placeholder="Senha"
                                            type="password"
                                            autoComplete="off"
                                            value={this.state.form.pass}
                                            onChange={this.updateForm} required />
                                    </Form.Group>
                                    <Form.Group>
                                        <CustomCheckbox
                                            name="save"
                                            label="Lembrar"
                                            value={this.state.form.save}
                                            onChange={this.updateForm}
                                        />
                                    </Form.Group>
                                </Form>
                                <div>
                                    <span className="text-danger">{this.state.error}</span>
                                </div>
                            </Card.Body>
                            <Card.Footer className="d-flex align-items-center">
                                <Card.Link href="#" className="mr-auto">Cadastrar</Card.Link>
                                <Button variant="primary" onClick={this.tryLogin}>Login</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col sm={1} md={3} lg={4}>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default LoginAdmin;
