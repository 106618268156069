import React from "react"
import { Container, Card, Table, Button, Badge } from 'react-bootstrap'
import { postTC } from './../helpers/apiAdmin'
import { getUserAdmin } from './../helpers/auth'
import Loading from './../components/Loading'

class Clientes extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            clients: [],
            loading: false,
        }
    }

    componentDidMount() {
        this.update()
    }

    update = () => {
        this.setState({ loading: true })
        postTC('api/dashboard', {
            ...getUserAdmin()
        }, (json) => {
            if (json.error) {
                this.setState({ error: json.ret })
            } else {
                console.log(json.ret)
                var today = (new Date()).getTime()
                json.ret.clientes = json.ret.clientes.map(c => {
                    var date = 100000000000000
                    var value = 0
                    json.ret.pags.forEach(pag => {
                        if (pag.id_cliente === c.id_cliente) {
                            var dataPayment = (new Date(pag.data + " 12:00:00Z")).getTime()
                            if (dataPayment > today && dataPayment < date) {
                                date = dataPayment
                                value = parseFloat(pag.valor)
                            }
                        }
                    })
                    if (date === 100000000000000) {
                        date = '--/--/----'
                    } else {
                        date = new Date(date)
                        date = date.toLocaleDateString(undefined, {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                        })
                    }
                    var jsonClient = JSON.parse(c.json)
                    jsonClient.status = jsonClient.status.toUpperCase()
                    return {
                        id_cliente: c.id_cliente,
                        ...jsonClient,
                        datePag: date,
                        value: value,
                    }
                })
                json.ret.clientes = json.ret.clientes.sort((a, b) => {
                    if (a.status < b.status)
                        return -1;
                    if (a.status > b.status)
                        return 1;
                    if (a.nome_admin.toLowerCase() < b.nome_admin.toLowerCase())
                        return -1;
                    if (a.nome_admin.toLowerCase() > b.nome_admin.toLowerCase())
                        return 1;
                    return 0;
                })
                const state = {
                    clients: json.ret.clientes,
                }
                console.log(state)
                this.setState(state)
            }
            this.setState({ loading: false })
        });
    }

    editClient = (cliente) => {
        this.props.history.push('/dashboard/cliente/' + cliente.id_cliente)
    }

    delClient = (cliente) => {
        if (window.confirm("Deseja excluir o cliente " + cliente.nome)) {
            this.setState({ loading: true })
            postTC('api/clientDel', {
                ...getUserAdmin(),
                id: cliente.id_cliente,
            }, (json) => {
                this.update()
            })
        }
    }

    render() {
        return (
            <Container>
                <Card>
                    <Loading show={this.state.loading} />
                    <Card.Header>Clientes</Card.Header>
                    <Card.Body><Table striped hover responsive>
                        <tbody>
                            <tr>
                                <th>#</th>
                                <th>Status</th>
                                <th></th>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Responsável</th>
                                <th>Proximo Pag</th>
                                <th>Valor</th>
                                <th></th>
                            </tr>
                            {this.state.clients.map((c, i) =>
                                <tr key={`${i}`}>
                                    <td>{i + 1}</td>
                                    <td><Badge variant={c.status === 'ATIVO' ? 'success' : 'danger'}>{c.status}</Badge></td>
                                    <td><div className="edit" onClick={() => this.editClient(c)} /></td>
                                    <td>{c.id_tudok}</td>
                                    <td>{c.nome}</td>
                                    <td>{c.nome_admin}</td>
                                    <td>{c.datePag}</td>
                                    <td>R$ {c.value.toFixed(2)}</td>
                                    <td><div className="del" onClick={() => this.delClient(c)} /></td>
                                </tr>
                            )}
                        </tbody>
                    </Table></Card.Body>
                    <Card.Footer><Button onClick={() => this.props.history.push('/dashboard/cliente')}>Novo Cliente</Button></Card.Footer>
                </Card>
            </Container>
        );
    }
}

export default Clientes
