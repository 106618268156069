import React from "react"
import { Form } from 'react-bootstrap'

class CustomSelector extends React.Component {

    render() {
        return (<Form.Group>
            <Form.Label>{this.props.label}</Form.Label>
            <Form.Control as="select" value={this.props.value} onChange={this.props.onChange} name={this.props.name}>
                {this.props.values.map(value => <option key={this.props.name+"-"+value}>{value}</option>)}
            </Form.Control>
        </Form.Group>)
    }
}

export default CustomSelector
