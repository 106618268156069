import React from "react"
import { Container, Card, Tab, Nav, Table } from 'react-bootstrap'
import { postTC } from './../helpers/apiAdmin'
import { getUserAdmin } from './../helpers/auth'
import ModalBackup from './ModalBackup'
import Loading from './../components/Loading'

class Backups extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            backups: [],
            pages: [],
            page: 0,
            showBackupModal: false,
            bkp: null,
            loading: false,
        }
    }

    componentDidMount() {
        this.update()
    }

    update = () => {
        this.setState({ loading: true })
        postTC('api/dashboard', {
            ...getUserAdmin()
        }, (json) => {
            if (json.error) {
                this.setState({ error: json.ret })
            } else {
                var pages = []
                for (var i = 0; i < json.ret.backups.length / 20; i++) {
                    pages.push(i + 1);
                }
                json.ret.backups = json.ret.backups.map(bkp => {
                    bkp.last_conection = new Date(bkp.last_conection + "Z")
                    bkp.time = bkp.last_conection.getTime()
                    return bkp
                })
                json.ret.backups = json.ret.backups.sort((a, b) => b.time - a.time)
                const state = {
                    backups: json.ret.backups,
                    pages: pages,
                }
                console.log(state)
                this.setState(state)
            }
            this.setState({ loading: false })
        });
    }

    showBackup = (bkp) => {
        //console.log(pag)
        this.setState({
            showBackupModal: true,
            bkp: bkp
        })
    }

    delBackup = (bkp) => {
        if (window.confirm("Deseja excluir o backup " + bkp.name)) {
            this.setState({ loading: true })
            postTC('api/delBackup', {
                ...getUserAdmin(),
                id_tudok: bkp.id_tudok,
            }, (json) => {
                this.update()
            })
        }
    }

    render() {
        return (
            <Container>
                <Card>
                    <Loading show={this.state.loading} />
                    <Tab.Container transition={false} defaultActiveKey="1">
                        <Card.Header>Backups</Card.Header>
                        <Card.Body><Nav variant="tabs">
                            {this.state.pages.map(page => <Nav.Item key={`page-${page}`}>
                                <Nav.Link eventKey={page}>{page}</Nav.Link>
                            </Nav.Item>)}
                        </Nav></Card.Body>
                        <Card.Body><Tab.Content>
                            {this.state.pages.map(page => <Tab.Pane eventKey={page} key={`page-${page}`}>
                                <PageBackup 
                                page={page} 
                                backups={this.state.backups} 
                                del={this.delBackup}
                                show={this.showBackup} />
                            </Tab.Pane>)}
                        </Tab.Content></Card.Body>
                    </Tab.Container>
                </Card>
                <ModalBackup
                    show={this.state.showBackupModal}
                    setShow={show => { this.setState({ showBackupModal: show }); this.update() }}
                    bkp={this.state.bkp} />
            </Container>
        );
    }
}

const PageBackup = (props) => {
    return (
        <Table striped hover responsive>
            <tbody>
                <tr>
                    <th></th>
                    <th></th>
                    <th>Ultima Conexão</th>
                    <th>Nome</th>
                    <th>Licença</th>
                    <th>Versão</th>
                    <th>Bloqueado</th>
                    <th>Info</th>
                    <th>ID TudOK</th>
                    <th></th>
                </tr>
                {props.backups.filter((b, i) => {
                    return i >= (props.page - 1) * 20 && i < (props.page) * 20
                }).map((b, i) => <tr key={`bkp-${i}`}>
                    <td><div className="edit" onClick={() => props.show(b)} /></td>
                    <td>{i + 1}</td>
                    <td>{b.last_conection.toLocaleDateString(undefined, {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    })}</td>
                    <td>{b.name}</td>
                    <td>{b.license_days}</td>
                    <td>{b.version}</td>
                    <td>{b.blocked === '0' ? 'Não' : 'Sim'}</td>
                    <td>{b.info}</td>
                    <td>{b.id_tudok}</td>
                    <td><div className="del" onClick={() => props.del(b)} /></td>
                </tr>)}
            </tbody>
        </Table>
    )
}

export default Backups
