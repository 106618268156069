export const isAdminLogged = () => {
    return localStorage.getItem('user-admin') != null
}

export const loginAdmin = (user) => {
    localStorage.setItem('user-admin', JSON.stringify(user))
}

export const getUserAdmin = () => {
    var user = localStorage.getItem('user-admin')
    return JSON.parse(user)
}

export const logoutAdmin = () => {
    localStorage.removeItem('user-admin')
}
