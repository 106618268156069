import React from "react";

class Error404 extends React.Component {

  render() {
    return (
      <>
        <small>Error404</small>
      </>
    );
  }
}

export default Error404;
