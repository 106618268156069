import React from "react"
import { Modal, Button, Form, Row, Col, Table } from 'react-bootstrap'
import { postTC, postBoletos, getBoletos } from './../helpers/apiAdmin'
import { getUserAdmin } from './../helpers/auth'
import CustomSelector from "../components/CustomSelector"
import CustomCheckbox from "../components/CustomCheckbox"
import FileBase64 from 'react-file-base64'

const StatusType = ["Aguardando", "Enviado", "Pago", "Pago com atraso", "Liberado do pagamento"]

class ModalPayment extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            error: '',
            files: [],
            boletos: [],
            cliente: {},
            form: {
                valor: '0.00',
                status: StatusType[0],
                data: '',
                recebido: '',
                descricao: '',
                id_pagamento: null,
                id_cliente: null,
                repetir: false,
            }
        }
    }

    save = () => {
        if (this.state.form.data === '') {
            this.setState({ error: 'Data de fatura inválida' })
        } else {
            postTC('api/payment', {
                ...getUserAdmin(),
                ...this.state.form,
            }, (json) => {
                if (json.error) {
                    this.setState({ error: json.ret })
                } else {
                    this.handleClose()
                }
            });
        }
    }

    updateForm = (event) => {
        let form = this.state.form
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value.replace(',', '.')
        form[event.target.name] = value
        this.setState({ form: form })
    }

    upload = () => {
        console.log(this.state.files)
        postBoletos('uploadBoleto.php', {
            ...this.state.files,
        }, (json) => {
            console.log(json)
            if (json.error) {
                this.setState({ error: json.ret })
            } else {
                var novosBoletos = [...this.state.boletos, ...json.ret]
                postTC('api/paymentBoleto', {
                    ...getUserAdmin(),
                    id_pagamento: this.state.form.id_pagamento,
                    boletos: JSON.stringify(novosBoletos),
                }, (json) => {
                    console.log(json)
                    this.setState({ boletos: novosBoletos, files: [] })
                })
            }
        })
    }

    sendEmail = (boleto) => {
        var email = prompt("Digite o email de Envio", this.props.cliente.email);
        if (email != null) {
            window.open(`https://sistematudok.com/boletos/sendEmail.php?email=${email}&parans=${this.getParans(boleto)}`, "_blank")
        }
    }

    sendWhatsapp = (boleto) => {
        getBoletos('getcode.php?parans=' + this.getParans(boleto), (json) => {
            console.log(json)
            if (json.error) {
                this.setState({ error: json.ret })
            } else {
                console.log(json)
                var tel = prompt("Digite o email o telefone", this.props.cliente.tel1)
                var text = `Olá, tudo bom?\r\n\r\n`
                text += `Aqui está sua fatura do mês de ${json.ret.fatura}.\r\n`
                text += `O vencimento é ${json.ret.vencimento}.\r\n\r\n`
                text += `https://sistematudok.com/boletos/boleto.php?parans=${this.getParans(boleto)}`
                if (tel != null) {
                    text = window.encodeURIComponent(text)
                    window.open(`https://api.whatsapp.com/send?phone=55${tel}&text=${text}`, "_blank");
                }
            }
        })
    }

    see = (boleto) => {
        window.open(`https://sistematudok.com/boletos/boleto.php?parans=${this.getParans(boleto)}`, "_blank")
        //window.open(`https://sistematudok.com/boletos/template.php?file=${boleto}&fatura=${this.state.form.data}&cliente=${this.props.cliente.nome}`, "_blank");
    }

    getParans = (boleto) => {
        var parans = {
            file: boleto,
            fatura: this.state.form.data,
            cliente: this.props.cliente.nome,
        }
        var paransEncoded = new Buffer(JSON.stringify(parans)).toString('base64')
        //console.log(paransEncoded)
        return paransEncoded.replace("=", "")
    }

    deleteBoleto = (boleto) => {
        var novosBoletos = this.state.boletos.filter(b => b !== boleto)
        postTC('api/paymentBoleto', {
            ...getUserAdmin(),
            id_pagamento: this.state.form.id_pagamento,
            boletos: JSON.stringify(novosBoletos),
        }, (json) => {
            console.log(json)
            this.setState({ boletos: novosBoletos })
        })
    }

    getFiles = (files) => {
        this.setState({ files: files })
    }

    updateStatus = (status) => {
        let form = this.state.form
        form['status'] = status
        this.setState({ form: form })
    }

    handleClose = () => {
        this.props.setShow(false)
    }

    onShow = () => {
        var form = this.state.form
        form.id_cliente = this.props.id_cliente
        form.repetir = false
        var boletos = []
        if (this.props.payment !== null) {
            console.log(this.props.payment)
            form.valor = parseFloat(this.props.payment.valor).toFixed(2)
            form.status = this.props.payment.status
            form.data = this.props.payment.data.toISOString().substr(0, 10)
            form.recebido = !this.props.payment.recebido.getTime() ? '' : this.props.payment.recebido.toISOString().substr(0, 10)
            form.descricao = this.props.payment.descricao
            form.id_pagamento = this.props.payment.id_pagamento
            boletos = JSON.parse(this.props.payment.boletos)
        }
        this.setState({ error: '', boletos, form })
    }

    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.handleClose} onShow={this.onShow}>
                <Modal.Header closeButton>
                    <Modal.Title>Pagamento {this.props.cliente ? this.props.cliente.nome : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Row>
                                <Col>
                                    <Form.Label>Valor</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="valor"
                                        value={this.state.form.valor}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <CustomSelector
                                        name="status"
                                        label="Status"
                                        values={StatusType}
                                        value={this.state.form.status}
                                        onChange={this.updateForm}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Data Pagamento</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="data"
                                        value={this.state.form.data}
                                        onChange={this.updateForm} required />
                                </Col>
                                <Col>
                                    <Form.Label>Data Recebimento</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="recebido"
                                        value={this.state.form.recebido}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control
                                        as="textarea" rows="3"
                                        name="descricao"
                                        value={this.state.form.descricao}
                                        onChange={this.updateForm} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomCheckbox
                                        name="repetir"
                                        label="Aplicar para os Proximos pagamentos"
                                        value={this.state.form.repetir}
                                        onChange={this.updateForm}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Table striped hover responsive>
                            <tbody>
                                {this.state.boletos.map(b => <tr key={b}>
                                    <td><Form.Label>{b}</Form.Label></td>
                                    <td><Button onClick={() => this.deleteBoleto(b)}>Deletar</Button></td>
                                    <td><Button onClick={() => this.sendEmail(b)}>Email</Button></td>
                                    <td><Button onClick={() => this.sendWhatsapp(b)}>Whatsapp</Button></td>
                                    <td><Button onClick={() => this.see(b)}>Ver</Button></td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </Form>
                    <div>
                        <FileBase64
                            multiple={true}
                            onDone={this.getFiles} />
                        <Button onClick={this.upload} style={{ display: this.state.files.length > 0 ? 'block' : 'none' }}>Upload</Button>
                    </div>
                    <div>
                        <span className="text-danger">{this.state.error}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" onClick={this.handleClose}>Close</Button>
                    <Button variant="primary" onClick={this.save}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalPayment
