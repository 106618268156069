import React from "react"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import { isAdminLogged } from "./helpers/auth"

import Error404 from "./Error404"
import LoginAdmin from "./admin/LoginAdmin"
import Client from "./admin/Client"
import Dashboard from "./admin/Dashboard" 
import Backups from "./admin/Backups"
import Clientes from "./admin/Clientes"
import Pagamentos from "./admin/Pagamentos"
import Contas from "./admin/Contas"
import Precos from "./admin/Precos"
import Categorias from "./admin/Categorias"
import Header from './components/Header'

const AdminRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        isAdminLogged() ? (<>
            <Header />
            <Component {...props}/>
        </>) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
    )} />
)

class App extends React.Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <AdminRoute exact path='/dashboard' component={Dashboard} />
                    <AdminRoute exact path='/dashboard/categorias' component={Categorias} />
                    <AdminRoute exact path='/dashboard/contas' component={Contas} />
                    <AdminRoute exact path='/dashboard/pagamentos' component={Pagamentos} />
                    <AdminRoute exact path='/dashboard/clientes' component={Clientes} />
                    <AdminRoute exact path='/dashboard/backups' component={Backups} />
                    <AdminRoute exact path='/dashboard/cliente/:id' component={Client} />
                    <AdminRoute exact path='/dashboard/cliente/' component={Client} />
                    <AdminRoute exact path='/dashboard/precos/' component={Precos} />
                    <Route exact path='/login' component={LoginAdmin} />
                    <AdminRoute exact path='*' component={Error404} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;